<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>经销商管理</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row class="row">
          <a-col :span="21">
            <a-row>
              <a-col :span="8">
                <a-form-item label="统一社会信用代码">
                  <a-input
                    v-decorator="['orgCode']"
                    allow-clear
                    @change="onOrgCodeChange"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="机构名称">
                  <a-input v-decorator="['dealerName']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="法人姓名">
                  <a-input v-decorator="['legalPerName']" allow-clear />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="联系人姓名">
                  <a-input v-decorator="['personName']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="联系人电话">
                  <a-input v-decorator="['personTel']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="合作状态">
                  <a-select
                    v-decorator="['cooperateStatus', { initialValue: -1 }]"
                    :options="statusList"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="3" class="col">
            <a-button
              type="primary"
              icon="search"
              class="search-button"
              @click="onSearch"
            >
              搜索
            </a-button>
            <a-button icon="undo" class="btn-reset" @click="onReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>

      <a-row class="button-container">
        <a-space>
          <a-button type="primary" @click="onAdd">
            绑定
          </a-button>
        </a-space>
      </a-row>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="venuId"
          @change="onPage"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">详情</a>
            <a @click="openModifyModal(record)">
              修改
            </a>
            <a
              v-if="record.cooperateResult === '合作中'"
              @click="changeDealerStatus(record)"
            >
              中止
            </a>
            <a
              v-if="record.cooperateResult === '中止合作'"
              @click="changeDealerStatus(record)"
            >
              恢复
            </a>
          </a-space>
        </a-table>
      </div>
    </div>

    <a-modal v-model="modifyVisible" title="修改经销商信息" @ok="onModify">
      <a-form :form="modifyForm" class="advanced-search-form">
        <a-form-item label="联系人姓名">
          <a-input
            v-decorator="[
              'personName',
              { rules: [{ required: true, message: '请输入联系人姓名' }] }
            ]"
            class="input"
          />
        </a-form-item>
        <a-form-item label="联系人电话">
          <a-input
            v-decorator="[
              'personTel',
              { rules: [{ required: true, message: '请输入联系人电话' }] }
            ]"
          />
        </a-form-item>
        <a-form-item label="联系地址">
          <a-cascader
            v-model="perAreaCode"
            :options="areaTreeData"
            :field-names="areaTreeNames"
            placeholder="请选择区域"
            @change="onChangePer"
          />
          <a-input
            v-decorator="[
              'personDetailAddress',
              {
                rules: [{ required: true, message: '请输入联系地址' }]
              }
            ]"
            placeholder="请输入详细地址"
          />
        </a-form-item>
        <a-form-item label="经销商编码">
          <a-input v-decorator="['dealerCode']" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  fetchDealerList,
  loadArea,
  modifyContact,
  modifyDealerStatus
} from "../../services/DealerService";
import moment from "moment";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "统一社会信用代码",
    dataIndex: "orgCode"
  },
  {
    title: "机构名称",
    dataIndex: "dealerName"
  },
  {
    title: "经销商类型",
    dataIndex: "dealerType"
  },
  {
    title: "法人姓名",
    dataIndex: "legalPerName"
  },
  {
    title: "联系人姓名",
    dataIndex: "personName"
  },
  {
    title: "联系人电话",
    dataIndex: "personTel"
  },
  {
    title: "绑定时间",
    dataIndex: "createTime"
  },
  {
    title: "合作状态",
    dataIndex: "cooperateResult"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      statusList: [
        { value: -1, label: "全部" },
        { value: 0, label: "中止合作" },
        { value: 1, label: "合作中" },
        { value: 2, label: "待认证" }
      ],
      modifyVisible: false,
      modifyForm: this.$form.createForm(this),
      perAreaCode: [],
      areaTreeData: [],
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      modifyInfo: {},
      factoryId: ""
    };
  },
  mounted() {
    this.onFetchList();
    this.onLoadArea();
    this.factoryId = localStorage.getItem("factoryId");
  },
  methods: {
    onOrgCodeChange(e) {
      setTimeout(() => {
        this.form.setFieldsValue({ orgCode: e.target.value.toUpperCase() });
      });
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.onFetchList(pagination.current);
    },
    // 新增经销商
    onAdd() {
      this.$router.push({ name: "DealerAdd" });
    },
    // 经销商详情
    onDetail(record) {
      this.$router.push({
        name: "DealerDetail",
        params: { factoryVenuId: record.factoryVenuId }
      });
    },
    onLoadDealerList(resp) {
      this.loading = false;
      this.tblData = resp.data.data.records;
      this.tblData.forEach(item => {
        item.createTime = moment(item.createTime).format("YYYY-MM-DD HH:mm:ss");
        if (item.contactList.length > 0) {
          item.personName = item.contactList[0].realName;
          item.personTel = item.contactList[0].mobile;
          item.detailAddress = item.contactList[0].detailAddress;
          item.provinceCode = item.contactList[0].provinceCode;
          item.cityCode = item.contactList[0].cityCode;
          item.countyCode = item.contactList[0].countyCode;
          item.contactId = item.contactList[0].contactId;
        }
        if (item.storeType && item.storeType !== "") {
          item.dealerType = `${item.dealerType}（${item.storeType}）`;
        } else {
          item.dealerType = `${item.dealerType}`;
        }
      });
      const data = resp.data.data;
      const pagination = { ...this.pagination };
      pagination.current = data.pageNum;
      pagination.total = data.total;
      pagination.pageSize = data.pageSize;
      pagination.showTotal = function(total, range) {
        return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
      };
      this.pagination = pagination;
    },
    // 经销商列表
    onFetchList() {
      this.loading = true;
      const params = {
        ...this.form.getFieldsValue(),
        cooperateStatus:
          typeof this.form.getFieldsValue().cooperateStatus !== "undefined"
            ? parseInt(this.form.getFieldsValue().cooperateStatus)
            : "",
        factoryId: localStorage.getItem("factoryId"),
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15
      };
      fetchDealerList(params)
        .then(resp => {
          if (resp.data.code === "FAILED") {
            this.$message.error(resp.data.errorMsg);
          } else {
            this.onLoadDealerList(resp);
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onSearch() {
      this.pagination.current = 1;
      this.onFetchList();
    },
    onModify() {
      if (this.modifyForm.getFieldsValue().personTel.length === 11) {
        const params = {
          dealerId: this.modifyInfo.dealerId,
          dealerName: this.modifyInfo.dealerName,
          mobile: this.modifyForm.getFieldsValue().personTel,
          orgCode: this.modifyInfo.orgCode,
          userId: this.modifyInfo.userId,
          userName: this.modifyForm.getFieldsValue().personName,
          address: {
            provinceCode: this.perAreaCode[0],
            cityCode: this.perAreaCode[1],
            countyCode: this.perAreaCode[2],
            detailAddress: this.modifyForm.getFieldsValue().personDetailAddress
          },
          dealerCode: this.modifyForm.getFieldsValue().dealerCode,
          factoryVenuId: this.modifyInfo.factoryVenuId
        };
        modifyContact(params).then(resp => {
          if (resp.data.code === "FAILED") {
            this.$message.error(resp.data.errorMsg);
          } else {
            this.$message.success("修改成功");
            this.modifyVisible = false;
            this.onFetchList();
          }
        });
      } else {
        this.$message.info("请输入正确的手机号");
      }
    },
    onChangePer(value) {
      this.perAreaCode = value;
      console.log(this.perAreaCode);
    },
    openModifyModal(record) {
      this.modifyVisible = true;
      this.modifyInfo = {
        dealerId: record.dealerId,
        dealerName: record.dealerName,
        mobile: record.personTel,
        orgCode: record.orgCode,
        userId: record.contactId,
        userName: record.personName,
        address: {
          provinceCOde: record.provinceCode,
          cityCode: record.cityCode,
          countyCode: record.countyCode,
          detailAddress: record.detailAddress
        },
        factoryVenuId: record.factoryVenuId
      };
      setTimeout(() => {
        this.modifyForm.setFieldsValue({
          personName: record.personName,
          personTel: record.personTel,
          personDetailAddress: record.detailAddress,
          dealerCode: record.dealerCode
        });
        if (
          record.provinceCode !== null &&
          record.cityCode !== null &&
          record.countyCode !== null
        ) {
          this.perAreaCode = [
            record.provinceCode,
            record.cityCode,
            record.countyCode
          ];
        }
      }, 100);
    },
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        } else {
          console.log(resp.data.errorMsg);
        }
      });
    },
    changeDealerStatus(record) {
      let cooperationFlag = false;
      if (record.cooperateResult === "合作中") {
        cooperationFlag = false;
      } else if (record.cooperateResult === "中止合作") {
        cooperationFlag = true;
      }
      const params = {
        cooperationFlag: cooperationFlag,
        factoryVenuId: record.factoryVenuId
      };
      modifyDealerStatus(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("修改成功");
          this.onFetchList();
        }
      });
    },
    onReset() {
      this.form.resetFields();
      this.onFetchList();
    }
  }
};
</script>

<style scoped>
.button-container {
  margin-bottom: 20px;
}

.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

.ant-form-item .ant-form-item-label {
  min-width: 130px;
}

.btn-operation {
  cursor: pointer;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.row {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 125px;
}

.btn-reset {
  margin-bottom: 8px;
}
</style>
